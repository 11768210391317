/**
 * ESOS API Documentation
 * ESOS API Documentation
 *
 * The version of the OpenAPI document: uk-esos-app-api 0.81.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';
import { AssigneeUserInfoDTO } from '../model/assigneeUserInfoDTO';
import { RequestTaskAssignmentDTO } from '../model/requestTaskAssignmentDTO';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root',
})
export class TasksAssignmentService {
  protected basePath = 'http://localhost:8080/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Assigns a task to a user
   * @param requestTaskAssignmentDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public assignTask(requestTaskAssignmentDTO: RequestTaskAssignmentDTO): Observable<any>;
  public assignTask(
    requestTaskAssignmentDTO: RequestTaskAssignmentDTO,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<any>>;
  public assignTask(
    requestTaskAssignmentDTO: RequestTaskAssignmentDTO,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<any>>;
  public assignTask(
    requestTaskAssignmentDTO: RequestTaskAssignmentDTO,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any>;
  public assignTask(
    requestTaskAssignmentDTO: RequestTaskAssignmentDTO,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (requestTaskAssignmentDTO === null || requestTaskAssignmentDTO === undefined) {
      throw new Error('Required parameter requestTaskAssignmentDTO was null or undefined when calling assignTask.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/v1.0/tasks-assignment/assign`,
      requestTaskAssignmentDTO,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns all users to whom can be assigned the provided task
   * @param taskId The task id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCandidateAssigneesByTaskId(taskId: number): Observable<Array<AssigneeUserInfoDTO>>;
  public getCandidateAssigneesByTaskId(
    taskId: number,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<AssigneeUserInfoDTO>>>;
  public getCandidateAssigneesByTaskId(
    taskId: number,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<AssigneeUserInfoDTO>>>;
  public getCandidateAssigneesByTaskId(
    taskId: number,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<AssigneeUserInfoDTO>>;
  public getCandidateAssigneesByTaskId(
    taskId: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (taskId === null || taskId === undefined) {
      throw new Error('Required parameter taskId was null or undefined when calling getCandidateAssigneesByTaskId.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.get<Array<AssigneeUserInfoDTO>>(
      `${this.configuration.basePath}/v1.0/tasks-assignment/${encodeURIComponent(String(taskId))}/candidate-assignees`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Returns all users to whom can be assigned the provided task type
   * @param taskId The current task id that user works on. Not related to the task type for which we search candidate assignees
   * @param taskType The task type for which you need to retrieve candidate assignees
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCandidateAssigneesByTaskType(
    taskId: number,
    taskType:
      | 'ORGANISATION_ACCOUNT_OPENING_APPLICATION_REVIEW'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_APPLICATION_SUBMIT'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_APPLICATION_EDIT'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_WAIT_FOR_EDIT'
      | 'ACTION_PLAN_P3_APPLICATION_SUBMIT'
      | 'ACCOUNT_CLOSURE_SUBMIT'
      | 'PROGRESS_UPDATE_1_P3_APPLICATION_SUBMIT'
      | 'PROGRESS_UPDATE_2_P3_APPLICATION_SUBMIT',
  ): Observable<Array<AssigneeUserInfoDTO>>;
  public getCandidateAssigneesByTaskType(
    taskId: number,
    taskType:
      | 'ORGANISATION_ACCOUNT_OPENING_APPLICATION_REVIEW'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_APPLICATION_SUBMIT'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_APPLICATION_EDIT'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_WAIT_FOR_EDIT'
      | 'ACTION_PLAN_P3_APPLICATION_SUBMIT'
      | 'ACCOUNT_CLOSURE_SUBMIT'
      | 'PROGRESS_UPDATE_1_P3_APPLICATION_SUBMIT'
      | 'PROGRESS_UPDATE_2_P3_APPLICATION_SUBMIT',
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<Array<AssigneeUserInfoDTO>>>;
  public getCandidateAssigneesByTaskType(
    taskId: number,
    taskType:
      | 'ORGANISATION_ACCOUNT_OPENING_APPLICATION_REVIEW'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_APPLICATION_SUBMIT'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_APPLICATION_EDIT'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_WAIT_FOR_EDIT'
      | 'ACTION_PLAN_P3_APPLICATION_SUBMIT'
      | 'ACCOUNT_CLOSURE_SUBMIT'
      | 'PROGRESS_UPDATE_1_P3_APPLICATION_SUBMIT'
      | 'PROGRESS_UPDATE_2_P3_APPLICATION_SUBMIT',
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<Array<AssigneeUserInfoDTO>>>;
  public getCandidateAssigneesByTaskType(
    taskId: number,
    taskType:
      | 'ORGANISATION_ACCOUNT_OPENING_APPLICATION_REVIEW'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_APPLICATION_SUBMIT'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_APPLICATION_EDIT'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_WAIT_FOR_EDIT'
      | 'ACTION_PLAN_P3_APPLICATION_SUBMIT'
      | 'ACCOUNT_CLOSURE_SUBMIT'
      | 'PROGRESS_UPDATE_1_P3_APPLICATION_SUBMIT'
      | 'PROGRESS_UPDATE_2_P3_APPLICATION_SUBMIT',
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<Array<AssigneeUserInfoDTO>>;
  public getCandidateAssigneesByTaskType(
    taskId: number,
    taskType:
      | 'ORGANISATION_ACCOUNT_OPENING_APPLICATION_REVIEW'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_APPLICATION_SUBMIT'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_APPLICATION_EDIT'
      | 'NOTIFICATION_OF_COMPLIANCE_P3_WAIT_FOR_EDIT'
      | 'ACTION_PLAN_P3_APPLICATION_SUBMIT'
      | 'ACCOUNT_CLOSURE_SUBMIT'
      | 'PROGRESS_UPDATE_1_P3_APPLICATION_SUBMIT'
      | 'PROGRESS_UPDATE_2_P3_APPLICATION_SUBMIT',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (taskId === null || taskId === undefined) {
      throw new Error('Required parameter taskId was null or undefined when calling getCandidateAssigneesByTaskType.');
    }
    if (taskType === null || taskType === undefined) {
      throw new Error(
        'Required parameter taskType was null or undefined when calling getCandidateAssigneesByTaskType.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.get<Array<AssigneeUserInfoDTO>>(
      `${this.configuration.basePath}/v1.0/tasks-assignment/${encodeURIComponent(String(taskId))}/candidate-assignees/${encodeURIComponent(String(taskType))}`,
      {
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
